<template>
    <div class="messenger-service-index">
        <client-only>
            <main class="messenger-service-index__content">
                <transition name="slide-from-left">
                    <aside v-if="showAside" class="content-aside">
                        <header class="content-aside__header">
                            <h1 class="content-aside__title">{{ $lang.pages.mensajeria.inbox }}</h1>
                            <ButtonGeneric
                                :info="{
                                    text: $lang.pages.mensajeria.send_message,
                                    handler: newMessage,
                                    icon: $assets.white.edit,
                                }"
                                class="content-aside__message-button"
                            />
                        </header>
                        <ul v-if="conversations.length > 0" class="content-aside__list">
                            <li
                                v-for="(item, key) in conversations"
                                :key="key"
                                class="list-item"
                                :class="item.has_unread_message ? 'bg-[#faf2ff]' : ''"
                                @click="() => changeChatThread(true, item.id)"
                            >
                                <MiscUserPic
                                    v-if="item.receiver"
                                    :user="item.receiver"
                                    class="list-item__photo"
                                />

                                <div class="list-item__details">
                                    <div v-if="item.receiver" class="list-item__info">
                                        <div class="list-item__name-and-rank">
                                            <div class="list-item__text">
                                                <h4 class="list-item__name">
                                                    {{ item.receiver.name }}
                                                </h4>
                                                <MiscBadge
                                                    v-if="item.receiver.role.name !== 'Usuario'"
                                                    :text="item.receiver.role.name"
                                                    text-style="hidden"
                                                    :full-badge="false"
                                                />
                                            </div>
                                            <img
                                                v-if="item.silent"
                                                :src="$assets.gray.mute"
                                                alt="notificaciones"
                                                height="10px"
                                                width="10px"
                                                class="h-4 w-4"
                                            />
                                        </div>
                                        <p v-if="item.message" class="list-item__timestamp text-gray-800">
                                            {{ getDateDifference(item.message.created_at) }}
                                        </p>
                                    </div>
                                    <div
                                        v-if="item.message"
                                        class="list-item__desc text-gray-800"
                                        v-html="item.message.message"
                                    />
                                </div>
                            </li>
                            <ButtonLoadMore
                                v-if="fetchButton.show"
                                :loading="fetchButton.loading"
                                :handler="getMoreConversations"
                                class="mt-4"
                            />
                        </ul>
                        <p v-else class="content-aside__empty-message">
                            {{ $lang.pages.mensajeria.no_readed_conversations }}
                        </p>
                    </aside>
                </transition>
                <transition name="slide-from-right">
                    <div v-if="!mobile || showChat" class="content-chat">
                        <template v-if="showChat">
                            <header v-if="receiver" class="content-chat__header">
                                <button
                                    class="content-chat__back-button"
                                    type="button"
                                    @click="() => changeChatThread(false, null)"
                                >
                                    <img :src="$assets.primary.arrowLeft" alt="back" />
                                </button>
                                <div class="content-chat__details">
                                    <picture class="content-chat__photo">
                                        <NuxtLink
                                            no-prefetch
                                            v-if="receiver.receiver"
                                            :to="'dashboard/' + receiver.receiver.id"
                                        >
                                            <MiscUserPic v-if="receiver.receiver" :user="receiver.receiver" />
                                        </NuxtLink>
                                    </picture>
                                    <div>
                                        <NuxtLink
                                            no-prefetch
                                            v-if="receiver.receiver"
                                            :to="'dashboard/' + receiver.receiver.id"
                                            class="content-chat__name"
                                        >
                                            {{ receiver.receiver.name }}
                                            <img
                                                v-if="receiver.silent"
                                                :src="$assets.gray.mute"
                                                alt="notificaciones"
                                                height="10px"
                                                width="10px"
                                                class="h-4 w-4"
                                            />
                                        </NuxtLink>
                                        <p class="content-chat__timestamp">
                                            {{
                                                getDateDifference(currentConversation.slice(-1)[0].created_at)
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="content-chat__options">
                                    <button
                                        type="button"
                                        class="content-chat__options-button"
                                        @click="() => handleOptionsMenuToggle(true)"
                                    >
                                        <img :src="$assets.gray.gear" alt="icon" />
                                    </button>
                                    <transition name="drop-from-right">
                                        <DropdownOptions
                                            v-if="showOptionsMenu"
                                            :options="optionsMenu"
                                            class="content-chat__dropdown"
                                            @close="() => handleOptionsMenuToggle(false)"
                                        />
                                    </transition>
                                </div>
                            </header>
                            <ul v-if="receiver" ref="$scrollToBottom" class="content-chat__list">
                                <button
                                    v-if="pagination.loadMoreMessages.show"
                                    type="button"
                                    :disabled="pagination.loadMoreMessages.loading"
                                    class="list-item__load-messages"
                                    @click="getMoreMessages(currentConversationId)"
                                >
                                    {{ $lang.pages.mensajeria.load_more_messages }}
                                    <img
                                        v-if="pagination.loadMoreMessages.loading"
                                        :src="$assets.primary.loading"
                                        class="spin h-6 w-6"
                                        alt="spinner"
                                    />
                                </button>

                                <li
                                    v-for="(message, key) in currentConversation"
                                    :key="key"
                                    class="list-item"
                                >
                                    <article
                                        class="list-item__message"
                                        :class="message.own_message ? 'send' : 'received'"
                                    >
                                        <header class="list-item__header">
                                            <MiscUserPic
                                                v-if="message.user"
                                                :user="message.user"
                                                class="list-item__photo"
                                            >
                                            </MiscUserPic>
                                            <div class="list-item__details">
                                                <div
                                                    v-if="message.user"
                                                    class="list-item__name"
                                                    :class="message.own_message ? 'flex justify-end' : ''"
                                                >
                                                    {{ message.user.username }}
                                                    <img
                                                        v-if="
                                                            !message.own_message && message.read_at === null
                                                        "
                                                        class="list-item__new-message"
                                                        :src="$assets.green.notification"
                                                    />
                                                </div>
                                                <p class="list-item__timestamp">
                                                    {{ getDate(message.created_at) }}
                                                </p>
                                            </div>
                                        </header>
                                        <div class="list-item__desc" v-html="message.message" />
                                    </article>
                                </li>
                            </ul>
                            <footer v-if="receiver" class="content-chat__footer">
                                <form
                                    class="content-chat__form"
                                    @submit.prevent="sendMessage(currentConversationId, formData.message)"
                                >
                                    <InputGeneric
                                        v-model.trim="formData.message"
                                        :placeholder="$lang.pages.mensajeria.write_your_message"
                                        class="content-chat__field"
                                    />
                                    <ButtonGeneric
                                        class="content-chat__submit-button"
                                        type="submit"
                                        :info="{
                                            text: $lang.pages.mensajeria.send,
                                            handler: () => null,
                                        }"
                                        :loading="sending"
                                    />
                                </form>
                            </footer>
                            <div v-else class="content-chat__no-chats">
                                {{ $lang.pages.mensajeria.to_start }}
                            </div>
                        </template>
                        <div v-else-if="!mobile && loadingConversation" class="content-chat__loader">
                            <img :src="$assets.primary.loading" class="spin h-20 w-20" alt="spinner" />
                        </div>
                    </div>
                </transition>
            </main>
        </client-only>
    </div>
</template>

<script lang="ts" setup>
import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'
import type { Helpers } from '~/types/helpers'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

definePageMeta({ middleware: ['auth-required'] })

const { $lang, $bp, $assets } = useNuxtApp()
const AuthStore = useAuthStore()
const RootStore = useRootStore()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const getConversation = (params?: object) =>
    $fetch<Api.Responses.User.Conversations>(endpoints.messages.conversations.path, {
        headers: buildHeaders(AuthStore.SessionToken),
        baseURL,
        method: 'GET',
        params,
    })

const { data: responseData, error } = await useAsyncData(
    'coupons-index',
    async () => await getConversation().catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let meta = ref(responseData.value.data.meta)
const conversations = ref(responseData.value.data.results)
const fetchButton = reactive({
    show:
        meta.value.total &&
        meta.value.current_page &&
        meta.value.last_page &&
        meta.value.total > 0 &&
        meta.value.current_page < meta.value.last_page,
    loading: false,
})
const pagination = reactive({
    loadMoreMessages: { show: true, loading: false, data: {} as Helpers.Meta },
})
const showOptionsMenu = ref(false)
const showAside = ref(true)
const showChat = ref(false)
const sending = ref(false)

const params = reactive({
    page: 2,
})

const currentConversationId = ref(0)
const currentConversation = ref([]) as Ref<Models.Message[]>
const formData = reactive({
    message: '',
})
const loadingConversation = ref(false)

const mobile = computed(() => process.browser && ($bp.mb || $bp.xs || $bp.sm || $bp.md))

const Route = useRoute()

const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.mensajeria.title_seo
const description = $lang.pages.mensajeria.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const optionsMenu = computed(() => {
    return [
        {
            title: $lang.pages.mensajeria.delete,
            icon: $assets.gray.remove,
            description: $lang.pages.mensajeria.delete_conversation,
            handler: () =>
                RootStore.setGenericModal({
                    type: 'confirm',
                    info: {
                        text: $lang.pages.mensajeria.want_delete_conversation,
                        continue: {
                            text: $lang.pages.mensajeria.delete,
                            handler: async () => {
                                const response = await $fetch<
                                    | Api.Responses.User.SuccessDeleteConversation
                                    | Api.Responses.User.FailedDeleteConversation
                                >(
                                    endpoints.messages.conversationDetail.path +
                                        '/' +
                                        currentConversationId.value,
                                    {
                                        method: 'DELETE',
                                        baseURL,
                                        headers: buildHeaders(AuthStore.SessionToken),
                                    },
                                )

                                if (response.feedback === 'data_success') {
                                    RootStore.setSiteNotification({
                                        text: $lang.pages.mensajeria.conversation_deleted,
                                        duration: 3000,
                                        dismissButtonText: $lang.pages.mensajeria.hide,
                                        type: 'success',
                                    })
                                    refreshChat()
                                } else {
                                    RootStore.setSiteNotification({
                                        text: $lang.pages.mensajeria.error,
                                        duration: 3000,
                                        dismissButtonText: $lang.pages.mensajeria.hide,
                                        type: 'error',
                                    })
                                }
                            },
                        },
                        cancel: {
                            text: $lang.pages.mensajeria.cancel,
                            handler: () => null,
                        },
                    },
                }),
        },
        {
            title: silentText(true),
            icon: $assets.gray.deactivate,
            description: `${silentText(true)} ${$lang.pages.mensajeria.mute_conversation}`,
            handler: () =>
                RootStore.setGenericModal({
                    type: 'confirm',
                    info: {
                        text: `${$lang.pages.mensajeria.want} ${silentText()} ${
                            $lang.pages.mensajeria.this_conversation
                        }`,
                        continue: {
                            text: silentText(true),
                            handler: async () => {
                                const silentPath = !receiver.value.silent ? '/silent' : '/un-silent'

                                const response = await $fetch<Api.Responses.User.SuccessSilentConversation>(
                                    endpoints.messages.conversationDetail.path +
                                        '/' +
                                        currentConversationId.value +
                                        silentPath,
                                    {
                                        headers: buildHeaders(AuthStore.SessionToken),
                                        baseURL,
                                        method: 'GET',
                                    },
                                )
                                if (response.feedback === 'data_success') {
                                    RootStore.setSiteNotification({
                                        text: $lang.pages.mensajeria.conversation_muted,
                                        duration: 3000,
                                    })
                                    refreshChat(currentConversationId.value)
                                } else {
                                    RootStore.setSiteNotification({
                                        text: $lang.pages.mensajeria.error_on_conversation_mute,
                                        duration: 3000,
                                        type: 'error',
                                    })
                                }
                            },
                        },
                        cancel: {
                            text: $lang.pages.mensajeria.cancel,
                            handler: () => null,
                        },
                    },
                }),
        },
    ]
})

const receiver = computed(() => {
    const receiver = conversations.value.find(
        (conversation: any) => conversation.id === currentConversationId.value,
    )

    return receiver!
})

watch(
    pagination,
    (newValue) => {
        if (newValue.loadMoreMessages.data.current_page === newValue.loadMoreMessages.data.last_page) {
            pagination.loadMoreMessages.show = false
        } else {
            pagination.loadMoreMessages.show = true
        }
    },
    {
        immediate: true,
        deep: true,
    },
)

onMounted(() => {
    showAside.value = true
    showChat.value = !mobile
    if (typeof Route.query.id === 'string') {
        changeChatThread(true, parseInt(Route.query.id))
    }
    AuthStore.updateActivitiesCount('messages')
})

const silentText = (mode: boolean = false) => {
    const desilenciar = mode ? $lang.pages.mensajeria.unmute_may : $lang.pages.mensajeria.unmute
    const silenciar = mode ? $lang.pages.mensajeria.mute_may : $lang.pages.mensajeria.mute
    return receiver.value.silent ? desilenciar : silenciar
}
const $scrollToBottom = ref()

const scrollToBottom = () => {
    const messagesBox = $scrollToBottom.value as HTMLUListElement
    if (messagesBox) {
        messagesBox.scrollTop = messagesBox.scrollHeight
    }
}
const sortByDate = () => {
    currentConversation.value = [
        ...currentConversation.value.sort((a: any, b: any) => {
            return b.created_at > a.created_at ? -1 : 1
        }),
    ]
}
const getDateDifference = (date: any) => {
    const duration = intervalToDuration({
        start: new Date(date as any),
        end: new Date(),
    })

    const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
    const nonzero = Object.entries(duration)
        .filter(([_, value]) => value)
        .map(([unit, _]) => unit)

    return formatDuration(duration, {
        format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1) as any,
        delimiter: ', ',
        locale: es,
    })
}
const getDate = (date: any) => {
    const newDate = date && parseISO(date)
    return newDate ? format(newDate, 'dd/MM/yyyy hh:mm') : ''
}
const newMessage = () => {
    RootStore.setGenericModal({
        type: 'message',
        info: { refresh: (id: number | null) => refreshChat(id) },
    })
}

const changeChatThread = async (show: boolean, id: number | null) => {
    if (id) {
        if (mobile.value) {
            RootStore.setLoadingStatus(true)
        } else {
            showChat.value = false
            loadingConversation.value = true
        }

        const res = await $fetch<Api.Responses.User.ConversationDetails>(
            endpoints.messages.conversationDetail.path + '/' + id,
            {
                baseURL,
                method: 'GET',
                headers: buildHeaders(AuthStore.SessionToken),
                params: { page: 1, per_page: 20 },
            },
        )

        if (res.feedback === 'data_success') {
            currentConversationId.value = id

            currentConversation.value = res.data.results

            if (mobile.value) {
                showAside.value = !show
            }

            showChat.value = show

            pagination.loadMoreMessages.data = res.data.meta
        }

        currentConversation.value = res.data.results.reverse()

        markAsRead(id.toString())

        if (mobile) {
            RootStore.setLoadingStatus(false)
        } else {
            loadingConversation.value = false
        }

        nextTick(() => scrollToBottom())
    } else if (mobile) {
        showAside.value = !show
        showChat.value = show
    }
}

const getMoreMessages = async (id: number) => {
    if (id) {
        pagination.loadMoreMessages.loading = true

        const messagesBox = $scrollToBottom.value as HTMLUListElement

        const currentScrollHeight = messagesBox.scrollHeight

        const res = await $fetch<Api.Responses.User.ConversationDetails>(
            endpoints.messages.conversationDetail.path + '/' + id,
            {
                baseURL,
                method: 'GET',
                headers: buildHeaders(AuthStore.SessionToken),
                params: { page: pagination.loadMoreMessages.data.current_page + 1, per_page: 20 },
            },
        )

        if (res.feedback === 'data_success') {
            currentConversation.value = [...currentConversation.value, ...res.data.results]

            sortByDate()

            pagination.loadMoreMessages.loading = false

            pagination.loadMoreMessages.data = res.data.meta

            nextTick(() => {
                const nextScrollHeight = messagesBox.scrollHeight

                messagesBox.scrollTop = nextScrollHeight - currentScrollHeight
            })
        } else {
            RootStore.setSiteNotification({
                duration: 3000,
                dismissButtonText: $lang.pages.mensajeria.hide,
                text: $lang.pages.mensajeria.unexpected_error,
                type: 'error',
            })
        }
    }
}

const refreshChat = async (id: number | null = null) => {
    showChat.value = false

    if (mobile) {
        RootStore.setLoadingStatus(true)
    } else {
        loadingConversation.value = true
    }

    const _conversations = await $fetch<Api.Responses.User.Conversations>(
        endpoints.messages.conversations.path,
        {
            baseURL,
            method: 'GET',
            headers: buildHeaders(AuthStore.SessionToken),
            params: { page: pagination.loadMoreMessages.data.current_page + 1, per_page: 20 },
        },
    )

    if (_conversations.feedback === 'data_success') {
        conversations.value = _conversations.data.results
    }

    if (id) {
        const _currentConversation = await $fetch<Api.Responses.User.ConversationDetails>(
            endpoints.messages.conversationDetail.path + '/' + id,
            {
                baseURL,
                method: 'GET',
                headers: buildHeaders(AuthStore.SessionToken),
                params: { page: 1, per_page: 20 },
            },
        )

        if (_currentConversation.feedback === 'data_success') {
            currentConversation.value = _currentConversation.data.results

            pagination.loadMoreMessages.show =
                !!_currentConversation.data.meta.last_page &&
                _currentConversation.data.meta.current_page < _currentConversation.data.meta.last_page

            pagination.loadMoreMessages.data = _currentConversation.data.meta

            sortByDate()

            currentConversationId.value = id

            if (mobile) {
                RootStore.setLoadingStatus(false)

                showAside.value = false

                showChat.value = true
            } else {
                showChat.value = true

                loadingConversation.value = false
            }

            nextTick(() => scrollToBottom())

            return
        }
    }

    if (mobile) {
        RootStore.setLoadingStatus(false)

        showAside.value = true

        showChat.value = false
    } else {
        showChat.value = false

        loadingConversation.value = false
    }
}

const getMoreConversations = async () => {
    fetchButton.loading = true

    const res = await $fetch<Api.Responses.User.Conversations>(endpoints.messages.conversations.path, {
        baseURL,
        method: 'GET',
        headers: buildHeaders(AuthStore.SessionToken),
        params: { page: params.page, per_page: 20 },
    })
    if (res.feedback === 'data_success') {
        conversations.value = [...conversations.value, ...res.data.results]
        const { current_page, last_page } = res.data.meta

        if (!last_page || current_page === last_page) {
            fetchButton.show = false
        } else {
            params.page = current_page + 1
        }
    }
    fetchButton.loading = false
}

const markAsRead = (id: string) => {
    const conversation = conversations.value.find((i: any) => i.id === Number(id))

    if (conversation) {
        conversation.has_unread_message = false
    }

    $fetch<Api.Responses.User.SuccessMessagesMarkAsRead>(
        endpoints.messages.markAsRead.path.replace('_ID_', id),
        {
            headers: buildHeaders(AuthStore.SessionToken),
            method: 'GET',
            baseURL,
            onResponse() {
                AuthStore.updateActivitiesCount('messages')
            },
        },
    ).catch((e) => e.data)
}

const sendMessage = async (id: number, newMessage: string) => {
    if (formData.message.length > 0 && !sending.value) {
        sending.value = true

        const res = await $fetch<Api.Responses.User.CreateMessage>(endpoints.messages.create.path, {
            method: 'POST',
            onResponse() {
                AuthStore.updateActivitiesCount('notifications')
            },
            baseURL,
            body: {
                conversation_id: id,
                message: newMessage,
            },
            headers: buildHeaders(AuthStore.SessionToken),
        })

        if (res.feedback === 'data_success') {
            formData.message = ''

            currentConversation.value = [...currentConversation.value, res.data]

            nextTick(() => scrollToBottom())
        } else {
            RootStore.setSiteNotification({
                duration: 3000,
                dismissButtonText: $lang.pages.mensajeria.hide,
                text: $lang.pages.mensajeria.error_on_send_message,
                type: 'error',
            })
        }
        sending.value = false
    }
}
const handleOptionsMenuToggle = (show: boolean): void => {
    showOptionsMenu.value = show
}
</script>

<style lang="postcss" scoped>
.messenger-service-index {
    --index-padding-top: 0px;
    --index-padding-bottom: 0px;

    @screen xs {
        --index-padding-top: theme('spacing.5');
        --index-padding-bottom: theme('spacing.5');
    }

    --content-footer-height: 70px;
    --content-header-height: 60px;
    --content-chat-height: calc(
        100vh - var(--index-padding-bottom) - var(--index-padding-top) - var(--total-header-height, 0px)
    );
    --content-list-height: calc(
        var(--content-chat-height) - var(--content-footer-height) - var(--content-header-height)
    );
    --content-messages-height: calc(var(--content-chat-height) - var(--content-header-height));

    &__content {
        @apply container px-0 xs:px-3 lg:grid lg:grid-cols-12 lg:gap-5;
        padding-top: var(--index-padding-top);
        padding-bottom: var(--index-padding-bottom);

        .content-aside {
            @apply bg-white xs:rounded-xl xs:border xs:border-gray-200 lg:col-span-4 2xl:col-span-3;
            height: var(--content-chat-height);

            &__header {
                @apply flex items-center justify-between border-b border-gray-200 px-3 py-2;
                height: var(--content-header-height);
            }
            &__title {
                @apply text-xl text-gray-800;
            }
            &__message-button {
                @apply border-site-primary bg-site-primary text-white;
            }
            &__list {
                @apply relative overflow-hidden overflow-y-auto;
                height: var(--content-messages-height);
                .list-item {
                    @apply flex cursor-pointer space-x-2 px-2 py-2;
                    &:not(:last-of-type) {
                        @apply border-b border-gray-200;
                    }
                    &__details {
                        @apply w-full;
                    }
                    &__photo {
                        @apply relative h-10 w-10 flex-none;
                        .user {
                            @apply h-full w-full rounded-full object-cover;
                        }
                    }
                    &__info {
                        @apply flex items-center justify-between gap-1 whitespace-nowrap;
                    }
                    &__name-and-rank {
                        @apply flex items-center gap-1;
                    }
                    &__text {
                        @apply flex flex-nowrap gap-0.5;
                    }
                    &__name {
                        @apply block overflow-hidden text-ellipsis whitespace-nowrap font-medium;
                    }
                    &__timestamp {
                        @apply ml-auto text-xs;
                    }
                    &__desc {
                        @apply line-clamp-1 text-sm;
                    }
                }
            }
            &__empty-message {
                @apply flex items-center justify-center p-4 text-center text-gray-800;
            }
        }
        .content-chat {
            @apply relative bg-white xs:rounded-xl xs:border xs:border-gray-200 lg:col-span-8 2xl:col-span-9;
            height: var(--content-chat-height);

            &__header {
                @apply flex items-center justify-between border-b border-gray-200 px-3 py-2;
                height: var(--content-header-height);
            }
            &__back-button {
                @apply h-10 w-6 flex-none lg:hidden;
                & ~ .content-chat__details {
                    @apply ml-2 lg:ml-0;
                }
                img {
                    @apply h-full w-full object-contain;
                }
            }
            &__details {
                @apply flex flex-grow items-center space-x-3;
            }
            &__photo {
                @apply h-10 w-10;
                img {
                    @apply h-full w-full rounded-full object-cover;
                }
            }
            &__name {
                @apply flex items-center gap-1 text-lg leading-6 text-gray-800 hover:underline;
            }
            &__timestamp {
                @apply text-xs text-gray-800;
            }
            &__options {
                @apply relative;
            }
            &__dropdown {
                @apply absolute right-0 top-full w-[200px] rounded-xl;
            }
            &__options-button {
                @apply h-6 w-6;
                img {
                    @apply h-full w-full object-contain;
                }
            }
            &__loader {
                @apply flex h-full items-center justify-center rounded-xl bg-white;
            }
            &__list {
                @apply relative space-y-4 overflow-y-auto px-4 py-2;
                height: var(--content-list-height);

                .list-item {
                    &__load-messages {
                        @apply flex w-full items-center justify-center text-center text-gray-800 hover:underline;
                    }
                    &__message {
                        @apply space-y-3;
                        &.send {
                            .list-item__header {
                                @apply flex flex-row-reverse text-right;
                            }
                            .list-item__photo {
                                @apply ml-2;
                            }
                            .list-item__desc {
                                @apply ml-auto;
                            }
                        }
                        &.received {
                            .list-item__header {
                                @apply flex;
                            }
                            .list-item__details {
                                @apply ml-2;
                            }
                        }
                    }
                    &__header {
                        @apply items-center;
                    }
                    &__photo {
                        @apply block h-8 w-8;
                        img {
                            @apply h-full w-full rounded-full object-cover;
                        }
                    }
                    &__name {
                        @apply flex text-sm text-gray-800;
                    }
                    &__new-message {
                        @apply h-4 w-4 self-center text-green-500;
                    }
                    &__timestamp {
                        @apply text-xs text-gray-800;
                    }
                    &__desc {
                        @apply max-w-max break-words break-all text-sm text-gray-800;
                    }
                }
            }
            &__footer {
                height: var(--content-header-height);
                @apply border-t px-2 py-3;
            }
            &__form {
                @apply flex items-center space-x-2;
            }
            &__field {
                @apply w-full;
            }
            &__no-chats {
                @apply flex h-full items-center justify-center rounded-xl bg-white text-gray-800;
            }
            &__submit-button {
                @apply bg-site-primary text-white;
            }
        }
    }
}
</style>
